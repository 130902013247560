import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EventSignUpDialog from '../dialogs/EventSignUpDialog';
import { inject } from "mobx-react";
import RestrictedAccessPage from "./RestrictedAccessPage";
// import { Redirect } from 'react-router-dom';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Schedule from "../Schedule";
import api from '../../api';
import { formatDateInterval } from '../../utils';
// import ReactPlayer from 'react-player/lazy';
import uiConfig from '../../uiConfig';

const useStyles = makeStyles(theme => {
  return ({
    ctaContainer: {
      // padding: 20,
      display: 'flex',
      alignItems: 'center',
    },
    ctaButton: {
      // margin: 10,
      padding: theme.spacing(2, 4),
      fontSize: 16,
      marginRight: 20,
      // fontWeight: fontWeight,
      // [theme.breakpoints.down("sm")]: {
      marginBottom: 10
      // }
    },
    header: {
      "& img": {
        width: '100%',
      },
    },
    networkingButton: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    eventImage: {
      width: '100%',
    }
  })
});

// const videoUrl = "https://sspmediacdn.azureedge.net/asset-4812c8dc-00a5-447e-817c-8f1175f4f986/swe2.mp4?sv=2017-04-17&sr=c&si=91530897-d3ad-4756-8c86-ad62d19df698&sig=IWHTLeWyiYreD6dusCh1wUf6i5sXQ2ZhG0KjV6QTtcs%3D&se=2030-10-28T08:59:15Z";

const EventHome = ({ event, store, user }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const classes = useStyles();
  const { register } = useParams();
  const isRegister = useMemo(() => register === "register", [register]);
  const { pathname } = useLocation();
  const history = useHistory();

  const handleDialogClose = async success => {
    if (success)
      await store.fetchEvents();
    setShowDialog(false);
    history.replace(pathname.replace("/register", ""));
    // logEvent("event registration completed");
  }

  useEffect(() => {
    let didCancel = false;
    if (!event?.id) return;
    api.getSchedule(event.id).then(data => {
      if (didCancel) return;
      setSchedule(data);
    });
    return () => didCancel = true;
  }, [event]);

  useEffect(() => {
    if (!event.hasAccess && !event.hasExhibitHallAccess && event.canRegister && isRegister) {
      setShowDialog(true);
    }
  }, [event, isRegister]);

  if (!event.hasAccess && !event.canRegister) {
    return (<RestrictedAccessPage />);
  }

  const { eventHome: uiSettings } = uiConfig;

  return (
    <>
      <EventSignUpDialog open={showDialog} handleClose={handleDialogClose} user={user} event={event} store={store} />
      <br />
      <br />
      <Grid container className={classes.header} spacing={2}>
        <Grid item xs={12} sm={(event.imageUrl && !event.imageUrl.includes("image.png")) ? 6 : 12} className={classes.ctaContainer}>
          <div>
            <Typography variant="h3" component="h1" color={uiSettings.titleColor} gutterBottom>Welcome to the {event.name}</Typography>
            <Typography variant="h4" component="h2" color={uiSettings.subtitleColor} >{formatDateInterval(event.startDate, event.endDate)}</Typography>
            <br />
            <br />
            {event.hasAccess &&
              <Button className={classes.ctaButton} variant="contained" color={uiSettings.liveColor}  size="large" component={Link} to={`/event/${event.alias}/live`} >Live</Button>
            }
            {(!event.hasAccess) &&
              <Button className={classes.ctaButton} variant="contained" color={uiSettings.registerColor}  size="large" onClick={() => history.push(pathname + "/register")}>{event.hasExhibitHallAccess ? "Upgrade" : "Register"}</Button>
            }
            {(event.enableExhibitHall && event.hasExhibitHallAccess) &&
              <Button className={classes.ctaButton} variant="contained" color={uiSettings.exhibitHallColor}  size="large" component={Link} to={`/event/${event.alias}/exhibit-hall`} >Exhibit Hall</Button>
            }
            {event.networkingRooms.length > 0 &&
              <>
                <br />
                {event.networkingRooms.map(room =>
                  <Button
                    key={room.id}
                    className={classes.networkingButton}
                    variant="contained"
                    color={uiSettings.networkingRoomColor}
                    component={room.url ? "a" : Link}
                    to={room.url ? undefined : `/event/${event.alias}/networking-room/${room.id}`}
                    target={room.url ? "_blank" : undefined}
                    rel={room.url ? "noreferrer noopener" : undefined}
                    href={room.url || undefined}
                  >
                    {room.name}
                  </Button>
                )}
              </>
            }
            {/* <Button className={classes.ctaButton} variant="contained" color="secondary" size="large" component={Link} to={`/event/${event.alias}/lounges`}>Lounges</Button> */}
          </div>
        </Grid>
        {(event.imageUrl && !event.imageUrl.includes("image.png")) &&
          <Grid item xs={12} sm={6}>
            <img src={event.imageUrl} className={classes.eventImage} />
            {/* <ReactPlayer className={classes.player} config={{ file: { attributes: { disablePictureInPicture: true, controlsList: 'nodownload' } } }} url={videoUrl} controls width='100%' height='100%' /> */}
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <br />
          <Typography variant="body2">Technical Support: <MuiLink href={`mailto:${uiConfig.supportEmail}`}>{uiConfig.supportEmail}</MuiLink></Typography>
        </Grid>
      </Grid>
      <div>
        <br />
        <hr />
        <br />
        <Typography variant="h4" color="primary" align="center" gutterBottom>Schedule</Typography>
        <Schedule data={schedule} />
      </div>
    </>
  );
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, store, events: store.events, user: store.currentUser,
}))(EventHome);
