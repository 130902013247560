const config = {
  CMEfilter: false,
  mainColor: "#1e49b2",
  primaryContrastText: "#fff",
  secondaryColor: "#6b7ccc",
  secondaryContrastText: "#fff",
  searchPlaceholder: "What do you want to learn?",
  loginText: "",
  requireAuth: true,
  showOnDemand: true,
  showBuy: false,
  showSignUp: false,
  showSponsors: false,
  showSponsorsPage: false,
  sponsorsPageCards: false,
  sponsorsCardsHorizontal: false,
  showEvents: false,
  eventsDebugMode: false,
  showProducts: false,
  showSchedule: false,
  trackPageScrollOffset: -80,
  showExhibitHall: true,
  showChat: true,
  enablePlaybackTracking: true,
  showPrivacyPolicy: false,
  // externalPrivacyPolicyUrl: "//www.ya.ru",
  // acceptPrivacyPolicyText: "Accept <Link>",
  emailHelperText: "",
  showSessionEvaluation: false,
  showRecordingEvaluation: false,
  showAskSpeaker: false,
  supportEmail: "info@slidespiel.com",
  portals: [
    { 
      year: 2020,
      logo: 'logo.png',
      signInRedirect: "/on-demand",
      trackImages: false,
      home: {
        minHeight: 500,
        height: '70vh',
        paperMarginType: 'Right',
        paperMargin: '0vw',
        paperMaxWidth: 800,
        paperColor: 'rgba(0,0,0,.0)',
        backgroundUrl: 'background.jpg',
        backgroundPosition: { x: 50, y: 55 },
        // backgroundRatio: 0.3797,
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: "#fff",
        buttonColor: "secondary",
        getLink: "/login",
        showHero: true,
        showCta: true,
        showCtaText: false,
        ctaPadding: [2, 4],
        //fontFamily: "Fira Sans",
        //fontWeight: 'bold',
      },
    },
  ],
  main: {
    logoMarginTop: 5,
    logoPadding: 0,
    logoHeight: 110,
    logoHeightXs: 50,
    progressBarTop: 119,
    progressBarTopXs: 55,
    childrenMarginTop: 119,
    childrenMarginTopXs: 50,
    searchColor: "primary",
    barColor: "default",
    barTextColor: "primary",
    barButtonColor: "primary"
  },
  footer: {
    // facebookUrl: "https://www.facebook.com/",
    // twitterUrl: "https://twitter.com/",
    // instagramUrl: "https://instagram.com/",
    // linkedInUrl: "https://www.linkedin.com/",
  },
  eventHome: {
    titleColor: "primary",
    subtitleColor: "secondary",
    liveColor: "secondary",
    registerColor: "primary",
    exhibitHallColor: "secondary",
    networkingRoomColor: "primary",
  }
};

export default config;
